import nickywood1 from "../../images/nickywood1.jpeg";
import nickywood2 from "../../images/nickywood2.jpeg";
import nickywood3 from "../../images/nickywood3.jpeg";
import nickywood4 from "../../images/nickywood4.jpeg";
import nickywood5 from "../../images/nickywood5.jpeg";
import nickywood6 from "../../images/nickywood6.jpeg";
import nickywood7 from "../../images/nickywood7.jpg";
import nickywood8 from "../../images/nickywood8.jpg";
import nickywood9 from "../../images/nickywood9.jpg";
import nickywood10 from "../../images/nickywood10.jpg";
import nickywood11 from "../../images/nickywood11.jpg";
import nickywood12 from "../../images/nickywood12.jpg";
import nickywood13 from "../../images/nickywood13.JPG";

const images = [
  {
    original: nickywood13,
    thumbnail: nickywood13,
  },
  {
    original: nickywood3,
    thumbnail: nickywood3,
  },
  {
    original: nickywood1,
    thumbnail: nickywood1,
  },
  {
    original: nickywood2,
    thumbnail: nickywood2,
  },

  {
    original: nickywood4,
    thumbnail: nickywood4,
  },
  {
    original: nickywood5,
    thumbnail: nickywood5,
  },
  {
    original: nickywood6,
    thumbnail: nickywood6,
  },
  {
    original: nickywood7,
    thumbnail: nickywood7,
  },
  {
    original: nickywood8,
    thumbnail: nickywood8,
  },
  {
    original: nickywood9,
    thumbnail: nickywood9,
  },
  {
    original: nickywood10,
    thumbnail: nickywood10,
  },
  {
    original: nickywood11,
    thumbnail: nickywood11,
  },
  {
    original: nickywood12,
    thumbnail: nickywood12,
  },
];

export default images;
